import Vue from 'vue/dist/vue.min';
import {pic} from './components/pic';

export function vm(){
    const v = new Vue({
        el: '#site-header',
		data: {
			toggle: false
		}
    });
	
    const c = new Vue({
        el: '#app-about',
        components: {
            pic: pic()
        },
    });
};