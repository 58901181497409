import ScrollMagic from 'scrollmagic';
import SmoothScroll from 'smooth-scroll/dist/smooth-scroll.polyfills.min';
import Rellax from 'rellax';

export function scrolEvents(controller , scene){
    if(document.getElementById('site-header')){
        scene.push(
            new ScrollMagic.Scene({
                triggerElement: 'body',
                offset: window.innerHeight * .6
            })
            .setClassToggle('#site-header','active').addTo(controller)
        )
    };
	
    if(document.getElementById('pagetop')){
        scene.push(
            new ScrollMagic.Scene({
                triggerElement: 'body',
                offset: window.innerHeight * 1.5
            })
            .setClassToggle('#pagetop','active').addTo(controller)
        )
    }
	
	const scroll_anime_name = 'scroll-anime';
	const scroll_anime_elem = document.getElementsByClassName(scroll_anime_name);
	if(scroll_anime_elem.length > 0){
		for(let i = 0; i < scroll_anime_elem.length; i++){
			let scroll_node = scroll_anime_elem[i];
			scene.push(
				new ScrollMagic.Scene({
					triggerElement: scroll_node,
					offset: -300
				})
				.reverse(false)
				.setClassToggle(scroll_node,'_active').addTo(controller)
			)
		}
	}
    
	const scroll_custum_name = 'scroll-custum';
	const scroll_custum_elem = document.getElementsByClassName(scroll_custum_name);
	if(scroll_custum_elem.length > 0){
		for(let i = 0; i < scroll_custum_elem.length; i++){
			let custum_node = scroll_custum_elem[i];
			scene.push(
				new ScrollMagic.Scene({
					triggerElement: custum_node,
					offset: -300
				})
				.reverse(false)
				.setClassToggle(custum_node,'_active').addTo(controller)
			)
		}
	}
	
	const scroll_img_name = 'scroll-img';
	const scroll_img_elem = document.getElementsByClassName(scroll_img_name);
	if(scroll_img_elem.length > 0){
		for(let i = 0; i < scroll_img_elem.length; i++){
			let scroll_img_node = scroll_img_elem[i];
			scene.push(
				new ScrollMagic.Scene({
					triggerElement: scroll_img_node,
					offset: -300
				})
				.reverse(false)
				.setClassToggle(scroll_img_node,'__active').addTo(controller)
			)
		}
	}
	
    setTimeout(function(){
        const loadActive = document.getElementsByClassName('loadActive');
        if(loadActive.length > 0){
            for(let i = 0; i < loadActive.length; i++){
                loadActive[i].classList.add('active');
            }
        }
    },200);
	
    
    if(document.getElementsByClassName('rellax').length > 0){
        const rellax = new Rellax('.rellax',{
            center:true
        });
    }
    if(document.getElementsByClassName('rellax-no').length > 0){
        const rellax_no = new Rellax('.rellax-no',{
            center:false
        });
    }

    const scroll = new SmoothScroll('a[href*="#"], [data-scroll]',{
		header: '#site-header',
        offset: 0,
		speed: 1000,
		easing: 'easeInOutQuart',
		speedAsDuration: true,
		durationMin: 1000
    });
	
	
	const sidebar = document.getElementById('sidebnr');
	const sidebarClose = document.getElementById('sidebnr-remove');
	
	if(sidebar){
		sidebarClose.addEventListener('click', ()=> {
			localStorage.sbnr = true;
			sidebar.classList.remove('active');
			setTimeout(function(){
				sidebar.remove();
			},600);
		});
		
		if(localStorage.getItem('sbnr') === null){
			scene.push(
				new ScrollMagic.Scene({
					triggerElement: 'body',
					offset: window.innerHeight * 1.5
				})
				.setClassToggle(sidebar,'active').addTo(controller)
			)
		}else{
			sidebar.remove();
		}
	}
	
	

	

}