import Swiper from 'swiper/swiper-bundle.min';
import anime from 'animejs';
import ScrollMagic from 'scrollmagic';
import {TweenMax} from "gsap";
import {ScrollMagicPluginGsap} from "scrollmagic-plugin-gsap";
import {ScrollMagicPluginIndicator} from "scrollmagic-plugins";
ScrollMagicPluginGsap(ScrollMagic, TweenMax);
TweenMax.defaultOverwrite = false;
ScrollMagicPluginIndicator(ScrollMagic);

export function index(controller , scene){

	const fvttl = document.getElementById('fvttl');
	const contain = document.getElementById('contain');
	let fastpos = window.innerHeight * -.4;
	if(fvttl){
        scene.push(
            new ScrollMagic.Scene({tweenChanges: true, triggerElement: contain, duration: '25%', offset: fastpos })
                .setTween(fvttl, {
                    opacity: 0,
                    transform: 'translateY(-10%)'
                })
                //.addIndicators({ name: "ttl" })
                .addTo(controller)
        );
	}
	
	const indexmes = document.getElementById('indexmes');
	if(indexmes){
        scene.push(
            new ScrollMagic.Scene({tweenChanges: true, triggerElement: indexmes, duration: '125%', offset: fastpos })
                .setTween(indexmes, {
                    transform: 'translateX(-15%)',
                })
                //.addIndicators({ name: "ttl" })
                .addTo(controller)
        );
	}
	
	/*
	let follower = document.getElementById('follower');
	let c = document.querySelectorAll(".clickable");
	if(follower){
		let
			fWidth = 80,
			delay = 10,
			mouseX = 0,
			mouseY = 0,
			posX = 0,
			posY = 0;

		TweenMax.to({}, .01, {
			repeat: -1,
			onRepeat: function() {
				posX += (mouseX - posX) / delay;
				posY += (mouseY - posY) / delay;
				TweenMax.set(follower, {
					css: {
						left: posX - (fWidth / 2),
						top: posY - (fWidth / 2)
					}
				});
			}
		});
		document.body.addEventListener("mousemove", function(e){
			mouseX = e.clientX;
			mouseY = e.clientY;
		});
		
		for (let i = 0; i < c.length; i++) {
		  c[i].removeEventListener("mouseenter",mouseEnter);
		  c[i].removeEventListener("mouseleave",mouseLeave);
		  c[i].addEventListener("mouseenter",mouseEnter);
		  c[i].addEventListener("mouseleave",mouseLeave);
		}
		
		function mouseEnter(){
			follower.classList.add('active');
		}
		function mouseLeave(){
			follower.classList.remove('active');
		}
	}
	*/
};