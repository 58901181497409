export function pic(){
    const temp = {
        data: function(){
        },
        template: 
        `
            <li>
				<figure>
					<img :src="img" :alt="ttl" />
					<figcaption v-html="ttl"></figcaption>
				</figure>
			</li>
        `,
		props: {
			ttl: String,
			img: String,
		},
    }
    return temp;
};